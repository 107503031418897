import styled from 'styled-components'

import Link from 'components/elements/Link'
import { Column } from 'components/common/Grid'

import media from 'utils/media'

export const Footer = styled.footer`
	width: 100%;
	padding: 25px;
	border-top: 1px solid ${({ theme }) => theme.colors.primary};
	background-color: ${({ theme }) => theme.colors.white};

	h6 {
		font-size: 15px;
		font-weight: bold;
	}
`

export const ColumnStyled = styled(Column)`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 10px;

	${media.md`
    padding-bottom: 0px;
    align-items: flex-start;
  `}
`

export const LinksStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	${media.md`
    align-items: flex-end;
  `}
`

export const LinkStyled = styled(Link)`
	font-size: 13px;
	line-height: 2;
`
