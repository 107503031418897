import styled from 'styled-components'

import { Column } from 'components/common/Grid'

export const HeaderStyled = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 25px 0;
	overflow: hidden;
	border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
	background-color: ${({ theme }) => theme.colors.white};
	z-index: 101;
`

export const ColumnStyled = styled(Column)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
`
