import React from 'react'

import { Container, Row } from 'components/common/Grid'

import { Footer, LinksStyled, LinkStyled, ColumnStyled } from './Footer'
import Socials from './Socials'

const links = [
	{
		title: 'Privacy Policy',
		url: 'https://resyk.org/privacy-policy/'
	},
	{
		title: 'Cookie Policy',
		url: 'https://resyk.org/cookie-policy/'
	},
	{
		title: 'Terms of use',
		url: 'https://resyk.org/terms-of-use/'
	}
]

export default () => (
	<Footer>
		<Container>
			<Row>
				<ColumnStyled width={[1, 0.5]}>
					<h6>Resyk &copy;2019</h6>
					<Socials />
				</ColumnStyled>

				<ColumnStyled width={[1, 0.5]}>
					<LinksStyled>
						{links.map((link, i) => (
							<LinkStyled key={`link_${i}`} {...link} />
						))}
					</LinksStyled>
				</ColumnStyled>
			</Row>
		</Container>
	</Footer>
)
