import React from 'react'
import styled, { css } from 'styled-components'

import Header from 'components/common/Header'

import Menu from './Menu'

const PageContentStyled = styled.div`
	height: 100%;
	overflow: hidden;

	${({ is_menu_open }) =>
		is_menu_open &&
		css`
			transform: scale(0.8) translateX(80%) translateZ(0) !important;
			border-radius: 20px;
			box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.15);

			& > div {
				overflow: hidden;
			}
		`};

	transform-origin: center;
	transition-property: transform, height, border-radius;
	transition-duration: 0.8s;
	transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
`

const Content = styled.div`
	overflow-y: auto;
	position: absolute;
	top: ${({ scroll_y }) => `calc(110px + ${scroll_y}px)`};

	height: calc(100% - 110px);
	width: 100%;
`

const OnClickCatcher = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 200;
`

const PageContent = ({ pathname, children }) => {
	const content_ref = React.useRef()

	const [is_menu_open, set_is_menu_open] = React.useState()
	const [scroll_y, set_scroll_y] = React.useState(0)

	React.useEffect(() => {
		content_ref.current.scrollTop = 0
	}, [pathname])

	const closeMenu = () => set_is_menu_open(false)

	const toggleMenu = () => {
		if (!is_menu_open) {
			set_scroll_y(window.scrollY)
		}

		set_is_menu_open(!is_menu_open)
	}

	return (
		<>
			<Menu isOpen={is_menu_open} closeMenu={closeMenu} />
			<PageContentStyled is_menu_open={is_menu_open}>
				{is_menu_open && <OnClickCatcher onClick={closeMenu} />}
				<Header toggleMenu={toggleMenu} />
				<Content ref={content_ref} scroll_y={scroll_y}>
					{children}
				</Content>
			</PageContentStyled>
		</>
	)
}

export default PageContent
