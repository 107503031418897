import React from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'

import media from 'utils/media'

const ModalStyled = styled(Modal)`
  position: absolute;
  left: 50%;
  top: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  width: 320px;
  width: 90%;
  padding: 25px;
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  outline: none;
  z-index: 103;

  button + button {
    margin-top: 15px;
  }

  ${media.sm`
    width: 60%;
  `}
`

export const ModalText = styled.p`
  margin: 30px 0;

  font-family: QuickSand;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 103;

  backdrop-filter: blur(3px) brightness(80%);;
`

export const CustomModal = ({ isOpen, closeModal, children }) => (
  <>
    {isOpen && <Overlay/>}

    <ModalStyled
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          background: 'transparent',
          zIndex: 105
        }
      }}
    >
      {children}
      {/* <ModalText>{title}</ModalText>
      <Button primary type='button' onClick={confirm}>
        {confirmBtnText}
      </Button>
      <Button primary border type='button' onClick={closeModal}>
        {denyBtnText}
      </Button> */}
    </ModalStyled>
  </>
)

export default CustomModal
