import React from 'react'
import { ThemeProvider } from 'styled-components'

import Seo from 'components/common/SEO'
import Main from 'components/common/Main'
import Footer from 'components/common/Footer'

import theme from 'utils/theme'
import GlobalStyles from './GlobalStyles'
import PageContent from './PageContent'

const Layout = ({ pageTitle, pageDescription, location, children }) => (
  <>
    <Seo
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      pathname={location.pathname}
    />
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <PageContent pathname={location.pathname}>
          <Main>{children}</Main>
          <Footer />
        </PageContent>
      </>
    </ThemeProvider>
  </>
)


export default Layout
