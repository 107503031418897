import React from 'react'
import { Link, navigate } from 'gatsby'
import isEmpty from 'lodash/isEmpty'

import Button from 'components/common/Button'
import Modal, { ModalText } from 'components/common/Modal'

const promptUrls = ['/app/resykable/create', '/app/resykable/create/']

const LinkWrapper = ({
  title,
  type,
  url,
  beforeOnClick,
  children,
  activeclassname,
  ...rest
}) => {
  const [is_modal_open, set_is_modal_open] = React.useState()

  const closeModal = () => set_is_modal_open(false)
  const confirmModal = () => {
    set_is_modal_open(false)
    navigate(url)
  }

  const navigateTo = url => e => {
    e.preventDefault()
    
    beforeOnClick && beforeOnClick()

    if (promptUrls.includes(window.location.pathname)) {
      set_is_modal_open(true)
    } else {
      navigate(url)
    }
  }

  if (type === 'internal') {
    return (
      <>
        <Modal isOpen={is_modal_open} closeModal={closeModal}>
          <ModalText>Are you sure you want to exit?</ModalText>
          <Button primary type='button' onClick={confirmModal}>
            Yes, close
          </Button>
          <Button primary border type='button' onClick={closeModal}>
            Continue editing
          </Button>
        </Modal>

        <Link
          to={url}
          onClick={navigateTo(url)}
          activeClassName={activeclassname}
          {...rest}
        >
          {children}
          {title}
        </Link>
      </>
    )
  }

  return (
    <a
      href={url}
      {...rest}
      target={
        !isEmpty(url) && url.substring(0, 6) === 'mailto' ? '_self' : '_blank'
      }
    >
      {children}
      {title}
    </a>
  )
}

export default LinkWrapper
