import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

import Link from 'components/elements/Link'

import media from 'utils/media'

export default function Socials() {
	return (
		<Wrapper>
			{list.map(({ name, url }) => (
				<Link key={name} url={url} type='external'>
					<FontAwesomeIcon icon={icons[name]} />
				</Link>
			))}
			<Link url='mailto:feedback@resyk.org'>
				<FontAwesomeIcon icon={icons['mail']} />
			</Link>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	margin-top: 20px;
	margin-bottom: 20px;

	${media.md`
    margin-bottom:0px;
  `}

	a {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		height: 50px;
		width: 50px;

		font-size: 25px;
		line-height: 30px;
		color: black;
		text-align: center;

		border: 2px solid black;
		border-radius: 50%;

		& + a {
			margin-left: 15px;
		}
	}
`

const list = [
	{
		name: 'twitter',
		url: 'https://twitter.com/resykit'
	},
	{
		name: 'facebook',
		url: 'https://facebook.com/resykit'
	},
	{
		name: 'instagram',
		url: 'https://instagram.com/resykit'
	}
]

const icons = {
	facebook: faFacebookF,
	twitter: faTwitter,
	instagram: faInstagram,
	mail: faEnvelope
}
