import { generateMedia } from 'styled-media-query'

export const breakpoints = {
  xxs: '0px',
  xs: '375px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
}

const media = generateMedia(breakpoints)

const xxs = media.greaterThan('xxs')
const xs = media.greaterThan('xs')
const sm = media.greaterThan('sm')
const md = media.greaterThan('md')
const lg = media.greaterThan('lg')
const xl = media.greaterThan('xl')

export default { xxs, xs, sm, md, lg, xl }
