import styled from 'styled-components'

import media from 'utils/media'

const Links = styled.ul`
	display: flex;
	align-items: center;

	a {
		cursor: pointer;
		padding: 0 25px;

		font-family: WorkSans;
		font-size: 20px;
		font-weight: 500;
		line-height: 1.17;
		color: ${({ theme }) => theme.colors.gray};
		text-align: center;

		:last-child {
			padding-right: 0;
		}
	}
`

export default Links

export const LinkWrapper = styled.span`
	position: relative;
	display: flex;
	flex-direction: column;

	font-family: QuickSand;
	font-weight: 500;
	line-height: 1.27;

	a {
		color: ${({ theme }) => theme.colors.primary};
	}

	img {
		position: absolute;
		width: calc(90% - 50px);
		left: 25px;
		bottom: -20px;
	}
`

const x = `

display: flex;
	}

	a {
		display: inline;
		padding-right: 50px;

		font-family: WorkSans;
		font-size: 20px;
		font-weight: 500;
		line-height: 1.17;
		color: ${({ theme }) => theme.colors.gray};
		text-align: center;
	}

	ul {
		display: none;
		align-items: baseline;

		${media.md`
      display: flex;
    `}

		li {
			&:last-child {
			}
		}
	}
`
