import styled, { css } from 'styled-components'

import media from 'utils/media'

const Button = styled.button`
  width: 100%;
  padding: 20px;

  font-size: 20px;
  font-weight: 500;
  text-align: center;

  border-radius: 8px;
  ${({ theme, border, primary }) =>
    border
      ? css`
          color: ${primary ? theme.colors.primary : theme.colors.gray};
          border: 1px solid currentcolor;
          background-color: transparent;
        `
      : css`
          color: ${theme.colors.white};
          background-color: ${primary ? theme.colors.primary : theme.colors.gray};
        `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: lightgray;
      border: 1px solid currentcolor;
      background-color: transparent;
      cursor: default;
    `}

  ${media.md`
    width: 300px;
  `}
`

export default Button
