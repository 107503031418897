import React from 'react'
import styled, { css } from 'styled-components'
import { navigate } from 'gatsby'
import { useStore } from 'effector-react'
import isEmpty from 'lodash/isEmpty'

import { getAddResykableEmailData, getFeedbackEmailData } from 'utils/emails'
import useAuth from 'utils/hooks/useAuth'

import AuthService from 'services/auth'
import Link from 'components/elements/PromptLink'
import { store } from 'components/common/GlobalState'

import avatar from 'assets/svg/avatar.svg'
import registryIcon from 'assets/svg/registry-white.svg'
import uploadIcon from 'assets/svg/upload-white.svg'
import emailIcon from 'assets/svg/email-white.svg'

const ContentWrapper = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
`

const MenuWrapper = styled.div`
	position: absolute;
	left: 0;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: auto;

	width: 70%;
	max-height: 100%;
	min-height: 70%;
	padding: 30px 0 30px 40px;

	color: ${({ theme }) => theme.colors.white};
	opacity: ${({ isopen }) => (isopen ? 1 : 0)};
	transition: opacity 0.25s;
`

const Avatar = styled.img`
	display: block;
	height: 60px;
	width: 60px;
	border-radius: 50%;
	border: 3px solid ${({ theme }) => theme.colors.white};
`

const MenuHeader = styled.div`
	display: flex;
	flex-direction: column;

	h5 {
		margin: 14px 0 8px 0;

		font-family: QuickSand;
		font-size: 21px;
		font-weight: normal;
	}

	a {
		font-size: 17px;
		font-weight: 500;
	}
`

const Links = styled.div`
	display: flex;
	flex-direction: column;
	padding: 50px 0;

	a {
		cursor: pointer;
		display: flex;
		align-items: center;

		font-family: QuickSand;
		font-size: 17px;
		font-weight: bold;

		span {
			display: flex;
			min-width: 60px;
		}
	}

	a + a {
		margin-top: 40px;
	}
`

const MenuFooter = styled.div`
	display: flex;
	flex-direction: column;

	a {
		cursor: pointer;
		font-family: QuickSand;
		font-size: 17px;
		font-weight: bold;
		color: ${({ theme }) => theme.colors.secondary};
	}

	a + a {
		margin-top: 24px;
	}
`

const MenuLink = ({ children, closeMenu, ...rest }) => (
	<Link type='internal' beforeOnClick={closeMenu} {...rest}>
		{children}
	</Link>
)

const Menu = ({ isOpen, closeMenu }) => {
	const { profile: userDetails } = useStore(store)
	const { isLoggedIn, profile = {} } = useAuth()

	const profileImage = userDetails.profileUri || (!isEmpty(profile) && profile.picture)

	const uploadResykableEmail = () => {
		if (userDetails && userDetails.resykEmail) {
			window.location = getAddResykableEmailData(userDetails.resykEmail)
		}
	}

	const feedbackEmail = () => {
		window.location = getFeedbackEmailData()
	}

	return (
		<ContentWrapper>
			<MenuWrapper isopen={isOpen}>
				<MenuHeader>
					<MenuLink url='/app/profile' closeMenu={closeMenu}>
						<Avatar src={profileImage || avatar} alt='user photo' />
					</MenuLink>

					<h5>{isLoggedIn && userDetails.displayName}</h5>

					<span>
						<MenuLink url='/app/profile/edit' closeMenu={closeMenu}>
							Edit Profile
						</MenuLink>
					</span>
				</MenuHeader>

				<Links>
					<MenuLink url='/app' closeMenu={closeMenu}>
						<span>
							<img src={registryIcon} alt='' />
						</span>
						View Registry
					</MenuLink>

					<MenuLink
						url='/app/resykable/create/'
						closeMenu={closeMenu}
						state={{ showFileDialog: true }}
					>
						<span>
							<img src={uploadIcon} alt='' />
						</span>
						Upload New Resykable
					</MenuLink>

					<a onClick={uploadResykableEmail}>
						<span>
							<img src={emailIcon} alt='' />
						</span>
						Email New Resykable
					</a>
				</Links>

				<MenuFooter>
					<a onClick={feedbackEmail}>feedback@resyk.org</a>
					<a onClick={AuthService.logout}>Log out</a>
				</MenuFooter>
			</MenuWrapper>
		</ContentWrapper>
	)
}

export default Menu
