import React from 'react'
import styled from 'styled-components'

import blobSvg from 'assets/svg/blobBg.svg'

export default function Main ({ children, className }) {
  return (
    <main className={className}>
      <Blobs>
        <Blob src={blobSvg} alt='Background blob' />
        <Blob src={blobSvg} alt='Background blob' />
      </Blobs>
      {children}
    </main>
  )
}

const Blobs = styled.div`
  position: relative;
  overflow: hidden;
`

const Blob = styled.img`
  position: absolute;
  z-index: -1;
  width: 1500px;
  height: 1200px;

  &:nth-of-type(1) {
    top: -25%;
    right: -25%;
    transform: rotate(10deg);
  }

  &:nth-of-type(2) {
    bottom: -25%;
    left: -25%;
    transform: rotate(90deg);
  }
`
