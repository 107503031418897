import { createGlobalStyle } from 'styled-components'

import fonts from 'assets/fonts'
import media from 'utils/media'

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "WorkSans";
    /* src: url(${`${fonts.WorkSansBoldEot}?#iefix`}) format("embedded-opentype"); */
    /* src: url(${fonts.WorkSansBoldWoff2}) format("woff2"), */
      /* url(${fonts.WorkSansBoldWoff}) format("woff"), */
      src: url(${fonts.WorkSansBoldTtf}) format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "WorkSans";
    /* src: url(${`${fonts.WorkSansLightEot}?#iefix`}) format("embedded-opentype"); */
    /* src: url(${fonts.WorkSansLightWoff2}) format("woff2"), */
      /* url(${fonts.WorkSansLightWoff}) format("woff"), */
      src: url(${fonts.WorkSansLightTtf}) format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "WorkSans";
    /* src: url(${`${fonts.WorkSansMediumEot}?#iefix`}) format("embedded-opentype"); */
    /* src: url(${fonts.WorkSansMediumWoff2}) format("woff2"), */
      /* url(${fonts.WorkSansMediumWoff}) format("woff"), */
      src: url(${fonts.WorkSansMediumTtf}) format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "WorkSans";
    /* src: url(${`${fonts.WorkSansRegularEot}?#iefix`}) format("embedded-opentype"); */
    /* src: url(${fonts.WorkSansRegularWoff2}) format("woff2"), */
      /* url(${fonts.WorkSansRegularWoff}) format("woff"), */
      src: url(${fonts.WorkSansRegularTtf}) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "QuickSand";
    src: url(${fonts.QuickSandMediumTtf}) format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }


  @font-face {
    font-family: "QuickSand";
    src: url(${fonts.QuickSandBoldTtf}) format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "QuickSand";
    /* src: url(${`${fonts.QuickSandEot}?#iefix`}) format("embedded-opentype"); */
    /* src: url(${fonts.QuickSandWoff2}) format("woff2"), */
      /* url(${fonts.QuickSandWoff}) format("woff"), */
      src: url(${fonts.QuickSandTtf}) format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "BalooThambiRegular";
    /* src: url(${`${fonts.BalooThambiRegularEot}?#iefix`}) format("embedded-opentype"); */
    /* src: url(${fonts.BalooThambiRegularWoff2}) format("woff2"), */
      /* url(${fonts.BalooThambiRegularWoff}) format("woff"), */
      src: url(${fonts.BalooThambiRegularTtf}) format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  html {
    height: 100%;
    overflow-y: auto !important;
  }

  body {
    height: 100%;
    overflow-x: hidden;
    background-color: ${({ theme }) => theme.colors.primary};

    --header-height: 110px;
    --footer-height: 137px;

    ${media.md`
      --header-height: 145px;
    `}
  }

  #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
  }

  main { 
    height: auto;
    min-height: calc(100% - var(--footer-height));
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.background};
  }

  button {
    appearance: none;
    margin: 0;
    padding: 0;
    outline: 0;
    background: none;
    border: 0;
    cursor: pointer;
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  svg {
    user-select: none;
  }
`

export default GlobalStyles
