import React from 'react'
import { navigate } from 'gatsby'
import { useStore } from 'effector-react'
import isEmpty from 'lodash/isEmpty'

import { getFeedbackEmailData } from 'utils/emails'
import useAuth from 'utils/hooks/useAuth'
import AuthService from 'services/auth'

import { Container, Row } from 'components/common/Grid'
import Link from 'components/elements/PromptLink'
import { store } from 'components/common/GlobalState'
import Button from 'components/common/Button'

import { HeaderStyled, ColumnStyled } from './Wrappers'
import Logo from './Logo'
import User from './User'
import Links, { LinkWrapper } from './Links'

import avatar from 'assets/svg/avatar.svg'
import squiggleImg from 'assets/svg/squiggle.svg'
import hamburgerSvg from 'assets/svg/hamburger.svg'

export default function Header({ toggleMenu }) {
	const { profile: userDetails } = useStore(store)
	const { isLoggedIn, profile = {} } = useAuth()
	const profileImage = userDetails.profileUri || (!isEmpty(profile) && profile.picture)

	const [is_mobile, set_is_mobile] = React.useState()

	React.useEffect(() => {
		handleResize()
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	const handleResize = () => {
		const breakpoint = 992
		set_is_mobile(window.innerWidth < breakpoint)
	}

	const goToJoinResyk = () => navigate('/app/')

	const feedbackEmail = () => {
		window.location = getFeedbackEmailData()
	}

	if (!isLoggedIn) {
		return (
			<HeaderStyled>
				<Container>
					<Row>
						<ColumnStyled>
							<Logo
								url='https://resyk.org/'
								type='internal'
								style={{ position: 'relative', left: 0 }}
							>
								RESYK
							</Logo>
							<Button
								primary='true'
								onClick={goToJoinResyk}
								style={{ maxWidth: 300, marginLeft: 20 }}
							>
								Join Resyk
							</Button>
						</ColumnStyled>
					</Row>
				</Container>
			</HeaderStyled>
		)
	}

	if (is_mobile) {
		return (
			<HeaderStyled>
				<Container>
					<Row>
						<ColumnStyled>
							<button onClick={toggleMenu}>
								<img src={hamburgerSvg} alt='hamburger-icon' />
							</button>
							<Logo type='internal' url='/app/'>
								RESYK
							</Logo>
							<User>
								<div>
									<div>
										<Link url='/app/profile' type='internal'>
											<img src={profileImage || avatar} alt='user photo' />
										</Link>
									</div>
								</div>
							</User>
						</ColumnStyled>
					</Row>
				</Container>
			</HeaderStyled>
		)
	}

	return (
		<HeaderStyled>
			<Container>
				<Row>
					<ColumnStyled>
						<Logo type='internal' url='/app/'>
							RESYK
						</Logo>

						<Links>
							<a onClick={feedbackEmail}>feedback@resyk.org</a>
							<LinkWrapper>
								<Link url='/app/resykable/create/' type='internal' title='Add resykable' />
								<img src={squiggleImg} alt='squiggle' />
							</LinkWrapper>

							<User>
								<div>
									<div>
										<Link url='/app/profile' type='internal'>
											<img src={profileImage || avatar} alt='user photo' />
										</Link>
									</div>
								</div>
							</User>

							<a onClick={AuthService.logout}>Log out</a>
						</Links>
					</ColumnStyled>
				</Row>
			</Container>
		</HeaderStyled>
	)
}
