import styled from 'styled-components'

import Link from 'components/elements/PromptLink'
import media from 'utils/media'

const Logo = styled(Link)`
	display: block;
	position: relative;
	z-index: 101;

	font-size: 36px;
	font-family: BalooThambiRegular;
	line-height: 1;
	color: ${({ theme }) => theme.colors.gray};

	${media.md`
    font-size: 50px;
  `}
`

export default Logo
