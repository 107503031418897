import styled from 'styled-components'
import starSvg from 'assets/svg/star.svg'

import media from 'utils/media'

const User = styled.div`
  & > div {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;

    & > div {
      position: relative;
      width: 60px;
      height: 60px;
      border-radius: 100%;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: -5px;
        width: 20px;
        height: 20px;
        background-image: url(${starSvg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 0;
        z-index: 102;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 60px;
      height: 60px;
      border-radius: 100%;
      border: 3px solid ${({ theme }) => theme.colors.primary};
      z-index: 101;
    }

    span {
      display: none;
      padding: 10px 6px 0 0;
      z-index: 101;

      font-family: WorkSans;
      font-size: 16px;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.primary};

      ${media.md`
        display: block;
      `}
    }
  }
`

export default User
