export default {
  colors: {
    background: '#f4f7fc',
    primary: '#3edd4a',
    secondary: '#cbf6ce',
    darkBlue: '#0055dc',

    error: '#cd5c5c',
    danger: '#ff0000',

    gray: '#818e94',
    light: '#C7C7C7',
    white: '#fff',
    black: '#000',
    silver: '#c1c1c1'
  },

  space: [0, 5, 10, 15, 24],
  breakpoints: ['426px', '48em', '62em', '75rem', '82.5rem']
}
