// // import WorkSansBoldEot from './worksans-bold.eot'
import WorkSansBoldTtf from './worksans-bold.ttf'
// // import WorkSansBoldWoff from './worksans-bold.woff'
// // import WorkSansBoldEotWoff2 from './worksans-bold.woff2'

// // import WorkSansLightEot from './worksans-light.eot'
import WorkSansLightTtf from './worksans-light.ttf'
// // import WorkSansLightWoff from './worksans-light.woff'
// // import WorkSansLightEotWoff2 from './worksans-light.woff2'

// // import WorkSansMediumEot from './worksans-medium.eot'
import WorkSansMediumTtf from './worksans-medium.ttf'
// // import WorkSansMediumWoff from './worksans-medium.woff'
// // import WorkSansMediumEotWoff2 from './worksans-medium.woff2'

// // import WorkSansRegularEot from './worksans-regular.eot'
import WorkSansRegularTtf from './worksans-regular.ttf'
// // import WorkSansRegularWoff from './worksans-regular.woff'
// // import WorkSansRegularEotWoff2 from './worksans-regular.woff2'

// // import QuickSandEot from './quicksand.eot'
import QuickSandTtf from './quicksand.ttf'
// // import QuickSandWoff from './quicksand.woff'
// // import QuickSandEotWoff2 from './quicksand.woff2'

// import QuickSandMediumEot from './quicksand-medium.eot'
import QuickSandMediumTtf from './quicksand-medium.ttf'
// import QuickSandMediumWoff from './quicksand-medium.woff'
// import QuickSandMediumWoff2 from './quicksand-medium.woff2'

// import QuickSandBoldEot from './quicksand-bold.eot'
import QuickSandBoldTtf from './quicksand-bold.ttf'
// import QuickSandBoldWoff from './quicksand-bold.woff'
// import QuickSandBoldWoff2 from './quicksand-bold.woff2'

// // import BalooThambiRegularEot from './baloothambi-regular.eot'
import BalooThambiRegularTtf from './baloothambi-regular.ttf'
// // import BalooThambiRegularWoff from './baloothambi-regular.woff'
// // import BalooThambiRegularWoff2 from './baloothambi-regular.woff2'

export default {
  // all imported fonts
  // WorkSansBoldEot,
  WorkSansBoldTtf,
  // WorkSansBoldWoff,
  // WorkSansBoldEotWoff2,

  // WorkSansLightEot,
  WorkSansLightTtf,
  // WorkSansLightWoff,
  // WorkSansLightEotWoff2,

  // WorkSansMediumEot,
  WorkSansMediumTtf,
  // WorkSansMediumWoff,
  // WorkSansMediumEotWoff2,

  // WorkSansRegularEot,
  WorkSansRegularTtf,
  // WorkSansRegularWoff,
  // WorkSansRegularEotWoff2,

  // QuickSandEot,
  QuickSandTtf,
  // QuickSandWoff,
  // QuickSandEotWoff2,

  // QuickSandMediumEot,
  QuickSandMediumTtf,
  // QuickSandMediumWoff,
  // QuickSandMediumWoff2,

  // QuickSandBoldEot,
  QuickSandBoldTtf,
  // QuickSandBoldWoff,
  // QuickSandBoldWoff2,

  // BalooThambiRegularEot,
  BalooThambiRegularTtf,
  // BalooThambiRegularWoff,
  // BalooThambiRegularWoff2
}
